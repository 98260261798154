import { Stack } from 'components';
import './style.css';

export function AboutOrderBlock() {
  return (
    <Stack
      orientation='vertical'
      gap={10}
      className='order-info-block-Nshe'
    >
      <h2>О ЗАКАЗЕ</h2>
      <div>После оформления заказа с вами свяжется менеджер для его подтверждения.</div>
    </Stack>
  );
}
