import { storageKeys } from 'common/consts';
import { IProduct } from 'common/models';
import { ICartProductBrief } from 'common/types';

export class CartService {
  private static instance: CartService | null = null;

  private constructor() {}

  static getInstance() {
    if (this.instance == null) {
      this.instance = new CartService();
    }

    return this.instance;
  }

  get(): ICartProductBrief[] {
    const storageString = localStorage.getItem(storageKeys.cart);

    if (storageString == null) {
      return [];
    }

    try {
      const products = JSON.parse(storageString) as ICartProductBrief[];
      return products;
    } catch {
      return [];
    }
  }

  set(product: IProduct, count: number) {
    const cartProducts = this.get();
    const cartProduct = cartProducts.find(x => x.productId === product.id);

    if (cartProduct != null) {
      cartProduct.count = count;
    } else {
      cartProducts.push({
        productId: product.id,
        count: count,
      });
    }

    const json = JSON.stringify(cartProducts);
    localStorage.setItem(storageKeys.cart, json);
  }

  remove(productId: string) {
    const cartProducts = this.get().filter(x => x.productId !== productId);
    const json = JSON.stringify(cartProducts);
    localStorage.setItem(storageKeys.cart, json);
  }

  clear() {
    localStorage.removeItem(storageKeys.cart);
  }
}
