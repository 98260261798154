import { ApiCategoryService, ApiProductsService } from 'common/api';
import { ApiErrorCode } from 'common/enums';
import { ApiError } from 'common/errors';
import { ICategoryBrief, IProduct } from 'common/models';
import { deviceAtom } from 'common/recoil';
import { CartService } from 'common/service';
import { ICartProductBrief, ICartProductFull } from 'common/types';
import { ModalLoader, Page } from 'components';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useImmer } from 'use-immer';
import { DesktopCart, MobileCart } from './frames';

interface IState {
  cartProducts?: ICartProductFull[];
  categories?: ICategoryBrief[];
  products?: IProduct[];
}

export function CartPage() {
  const [state, updateState] = useImmer<IState>({});
  const device = useRecoilValue(deviceAtom);

  useEffect(() => {
    loadCartProducts();
    loadCategories();
    loadProducts();
  }, []); // eslint-disable-line

  const loadCategories = async () => {
    const categoriesService = ApiCategoryService.getInstance();
    const categories = await categoriesService.getAll();
    updateState(x => {
      x.categories = categories;
    });
  };

  const loadProducts = async () => {
    const productsService = ApiProductsService.getInstance();
    const products = await productsService.search(1, { random: true });
    updateState(x => {
      x.products = products.result;
    });
  };

  const loadCartProducts = async () => {
    const cartService = CartService.getInstance();
    const cartProductsBrief = cartService.get();
    const cartProductsPromiseList = cartProductsBrief.map(x => loadCartProduct(x));
    const cartProductsFullNullable = await Promise.all(cartProductsPromiseList);
    const cartProductsFull = cartProductsFullNullable.filter(x => x != null);

    updateState(x => {
      x.cartProducts = cartProductsFull as any;
    });
  };

  const loadCartProduct = async (cartProductBrief: ICartProductBrief): Promise<ICartProductFull | null> => {
    const productsService = ApiProductsService.getInstance();

    try {
      const product = await productsService.getById(cartProductBrief.productId);
      return {
        product: product,
        count: cartProductBrief.count,
      };
    } catch (e) {
      if (e instanceof ApiError && e.code === ApiErrorCode.NotFound) {
        const cartService = CartService.getInstance();
        cartService.remove(cartProductBrief.productId);
        return null;
      }

      throw e;
    }
  };

  const handleProductCountChangeClick = (product: IProduct, delta: number) => {
    const cartService = CartService.getInstance();
    const updatingCartProuctIndex = (state.cartProducts ?? []).findIndex(x => x.product.id === product.id);

    if (updatingCartProuctIndex >= 0) {
      const updatedCartProducts = (state.cartProducts ?? []).filter(x => x.product.id !== product.id);
      const updatingCartProduct = (state.cartProducts ?? [])[updatingCartProuctIndex];

      const count = updatingCartProduct.count + delta;

      if (count > 0) {
        const updatedCartProduct = { ...updatingCartProduct, count: updatingCartProduct.count + delta };
        cartService.set(product, updatedCartProduct.count);
        updatedCartProducts.splice(updatingCartProuctIndex, 0, updatedCartProduct);
      } else {
        cartService.remove(product.id);
      }

      updateState(x => {
        x.cartProducts = updatedCartProducts;
      });
    }
  };

  const description = 'Корзина товаров. Здесь вы можете оформить заказ на выбранные вами товары.';

  if (state.cartProducts == null || state.products == null || state.categories == null) {
    return (
      <Page
        title='Корзина'
        description={description}
      >
        <ModalLoader />
      </Page>
    );
  }

  const content =
    device === 'desktop' ? (
      <DesktopCart
        cartProducts={state.cartProducts}
        products={state.products}
        categories={state.categories}
        onIncreaseClick={product => handleProductCountChangeClick(product, 1)}
        onDecreaseClick={product => handleProductCountChangeClick(product, -1)}
      />
    ) : (
      <MobileCart
        cartProducts={state.cartProducts}
        products={state.products}
        categories={state.categories}
        onIncreaseClick={product => handleProductCountChangeClick(product, 1)}
        onDecreaseClick={product => handleProductCountChangeClick(product, -1)}
      />
    );

  return (
    <Page
      title='Корзина'
      description={description}
    >
      {content}
    </Page>
  );
}
